<template>
  <div
    @click="media_data_navigation(card_data.id, card_data.entity)"
    class="flex flex-col cursor-pointer swiper-slide"
  >
    <template v-if="card_page === 'recent_view'">
      <div
        class="relative flex justify-center items-center xl:h-[400px] sm:h-[285px] max-[640px]:h-[300px] bg-custom-gradient"
      >
        <div class="absolute right-3 top-3 z-10">
          <FavCardIconComponent :card_data="card_data" />
        </div>
        <img
          :src="card_data.cover_image"
          :alt="card_data.title"
          class="absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto"
        />
      </div>
    </template>

    <template v-else-if="card_page === 'favorite_view'">
      <div
        class="relative flex justify-center items-center xl:h-[400px] sm:h-[285px] max-[640px]:h-[300px] bg-custom-gradient"
      >
        <FavCardIconComponent :card_data="card_data" />
        <img
          :src="card_data.cover_image"
          :alt="card_data.title"
          class="absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto"
        />
      </div>
    </template>

    <template v-else>
      <div
        class="relative flex justify-center items-center sm:h-[429px] max-[640px]:h-[414px] bg-custom-gradient"
      >
        <div class="absolute right-3 top-3 z-10">
          <FavCardIconComponent :card_data="card_data" />
        </div>
        <img
          :src="card_data.cover_image ? card_data.cover_image : card_data.image"
          class="absolute top-0 bottom-0 right-0 left-0 max-w-full max-h-full m-auto"
        />
      </div>
    </template>

    <div class="flex justify-between items-center">
      <!-- Conditionally render origin if it exists -->
      <span
        v-if="card_data.origin"
        class="text-xs text-[#9CA3AF] font-bold mt-3"
      >
        {{ card_data.origin }}
      </span>

      <!-- Conditionally render media_type_display if it exists -->
      <span
        v-if="card_data.media_type_display"
        class="bg-blue px-3 text-white text-lg text-center rounded-full mt-2"
      >
        {{ card_data.media_type_display }}
      </span>
    </div>

    <p
      v-if="card_data.title || card_data.name"
      class="text-lg font-bold mt-3 break-words sm:h-[60px] sm:line-clamp-2"
    >
      {{ card_data.title ? card_data.title : card_data.name }}
    </p>

    <div class="flex items-center"></div>

    <!-- Conditionally render genre if it exists and is an array -->
    <p
      v-if="card_data.genre && card_data.genre.length > 0"
      class="text-sm text-[#9CA3AF] font-bold break-words sm:h-11 sm:line-clamp-2"
    >
      {{ card_data.genre.join(', ') }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import FavCardIconComponent from '../fav_card_icon/fav_card_icon_component.vue';
import { logout_messages } from '@/common_variables/logout_messages';
import { media_page_navigation } from '../common_routing/media_page_routing';
import { specific_page_routing } from '../common_routing/specific_data_routing';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'CardComponent',
  components: {
    FavCardIconComponent,
  },
  props: {
    card_data: {
      type: Object,
      required: true,
    },
    card_page: {
      type: String, // or the appropriate type
      required: false,
    },
  },
  data() {
    return {
      isLogin: false,
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
      this.set_show_login_popup(false);
    }
  },

  methods: {
    ...mapActions('fav_card_icon_store', ['favourite_data_api']),
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    media_data_navigation(id, type) {
      if (Object.keys(all_entity_type_values).includes(type)) {
        if (this.isLogin) {
          if (
            this.card_data.linked_media_id &&
            this.card_data.linked_media_type
          ) {
            media_page_navigation(
              this.$router,
              this.card_data.linked_media_id,
              this.card_data.name,
              this.card_data.linked_media_type,
            );
          } else {
            specific_page_routing(this.$router, id, type);
          }
        } else {
          this.set_show_login_popup(true);
          this.set_msg(logout_messages.specific_data_message);
        }
      } else {
        media_page_navigation(
          this.$router,
          id,
          this.card_data.title,
          this.card_data.media_type,
        );
      }
    },
  },
};
</script>
