<template>
  <header class="flex flex-row justify-between py-8 text-black text-lg bg-white relative px-8">
    <div class="logo">
      <a href="/"><img src="../../images/logo.png" alt="Logo" /></a>
    </div>

    <div class="flex justify-items-center items-center">
      <ul :class="{
        'max-[1023px]:left-0': isMenuOpen,
        'max-[1023px]:-left-full': !isMenuOpen,
      }"
        class="nav-links font-bold bg-white flex items-center gap-8 lg:flex-row lg:static fixed top-0 mr-52 h-screen lg:h-auto duration-500 ease-in-out z-[100] w-4/5 max-w-[300px] max-[1023px]:flex-col max-[1023px]:items-start max-[1023px]:gap-4 max-[1023px]:py-5 max-[1023px]:px-8">
        <div v-if="isMenuOpen" class="w-full mb-8 lg:hidden">
          <img src="../../images/logo.png" alt="Logo" class="w-32 mx-auto" />
        </div>
        <li :class="{ 'text-blue': header_option === 'Home' }" class="cursor-pointer whitespace-nowrap">
          <a @click="setHeaderOption('Home')" class="hover:text-blue flex items-center">
            <span class="icon-home text-xl mr-3 lg:hidden"></span>
            <span class="text-sm lg:text-base">Home</span>
          </a>
        </li>
        <li :class="{ 'text-blue': header_option === 'Satisfy_My_Curiosity' }" class="cursor-pointer whitespace-nowrap">
          <a @click="setHeaderOption('Satisfy_My_Curiosity')" class="hover:text-blue flex items-center">
            <span class="icon-curiosity text-xl mr-3 lg:hidden"></span>
            <span class="text-sm lg:text-base">Satisfy My Curiosity</span>
          </a>
        </li>
        <li :class="{ 'text-blue': header_option === 'Show_Off_My_Knowledge' }"
          class="cursor-pointer whitespace-nowrap">
          <a @click="setHeaderOption('Show_Off_My_Knowledge')" class="hover:text-blue flex items-center">
            <span class="icon-knowledge text-xl mr-3 lg:hidden"></span>
            <span class="text-sm lg:text-base">Show Off My Knowledge</span>
          </a>
        </li>
        <li :class="{ 'text-blue': header_option === 'Explore' }" class="cursor-pointer whitespace-nowrap">
          <a @click="navigateToExplore" class="hover:text-blue flex items-center">
            <span class="icon-knowledge text-xl mr-3 lg:hidden"></span>
            <span class="text-sm lg:text-base">Explore</span>
          </a>
        </li>
      </ul>
      <div v-if="!isTokenEmpty && profile_data" class="flex justify-items-center items-center ml-[70px]"
        id="userDropdownBtn">
        <div class="flex justify-center items-center cursor-pointer relative" @click="openUserDropdown()">
          <span class="text-base font-normal">
            {{
              !isTokenEmpty
                ? this.full_name_header === '' ||
                  this.full_name_header == undefined
                  ? profile_data.result.full_name
                  : this.full_name_header
                : ''
            }}
          </span>
          <img :src="profile_data.result.avatar" alt="User" class="rounded-full w-8 h-8 ml-5" />
          <!-- user dropdown start  -->
          <div
            class="user-dropdown absolute top-14 z-50 bg-white right-0 p-5 rounded-xl w-[305px] transition-opacity max-[375px]:-right-12 duration-1000 ease-in-out hidden">
            <div class="flex items-center border-b-2 border-borderColor pb-5">
              <img :src="profile_data.result.avatar" alt="User" class="rounded-full w-10 h-10" />
              <div class="flex flex-col ml-3">
                <p class="flex items-center">
                  <span class="text-lg font-medium mr-2">{{
                    !isTokenEmpty
                      ? this.full_name_header === '' ||
                        this.full_name_header == undefined
                        ? profile_data.result.full_name
                        : this.full_name_header
                      : ''
                  }}</span>
                  <span
                    class="icon-diamond text-sm text-blue bg-silver rounded-full w-[23px] h-[23px] flex items-center justify-center"></span>
                </p>
                <p class="text-[#999] text-sm">
                  {{ !isTokenEmpty ? profile_data.result.email : '' }}
                </p>
              </div>
            </div>
            <div class="pt-6 pb-0">
              <ul class="flex flex-col">
                <li @click="navigate_to_stats" class="flex items-center pb-5">
                  <span class="icon-stats text-lg text-black fill-black"></span>
                  <span class="text-lg font-medium ml-4">Badges & Levels</span>
                </li>
                <li @click="navigate_to_xp_page" class="flex items-center pb-5">
                  <span class="icon-profile-setting text-lg text-black fill-black"></span>
                  <span class="text-lg font-medium ml-4">XP <span class="text-blue">({{ profile_data.result.xp
                      }})</span></span>
                </li>
                <li @click="navigate_to_journal" class="flex items-center pb-5">
                  <span class="icon-stats text-lg text-black fill-black"></span>
                  <span class="text-lg font-medium ml-4">Journal</span>
                </li>
                <li @click="navigate_to_profile_settings" class="flex items-center pb-5">
                  <span class="icon-profile-setting text-lg text-black fill-black"></span>
                  <span class="text-lg font-medium ml-4">Profile Settings</span>
                </li>
                <li @click="signout_user()" class="flex items-center pt-3">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 10.7C13.3866 10.7 13.7 10.3866 13.7 10C13.7 9.6134 13.3866 9.3 13 9.3L13 10.7ZM2.50503 9.50502C2.23166 9.77839 2.23166 10.2216 2.50503 10.495L6.9598 14.9497C7.23316 15.2231 7.67638 15.2231 7.94975 14.9497C8.22311 14.6764 8.22311 14.2332 7.94975 13.9598L3.98995 10L7.94975 6.0402C8.22312 5.76683 8.22312 5.32362 7.94975 5.05025C7.67638 4.77688 7.23317 4.77688 6.9598 5.05025L2.50503 9.50502ZM13 9.3L3 9.3L3 10.7L13 10.7L13 9.3Z"
                      fill="black" />
                    <path
                      d="M10 6.5V5.5C10 4.11929 11.1193 3 12.5 3H14.5C15.8807 3 17 4.11929 17 5.5V14.5C17 15.8807 15.8807 17 14.5 17H12.5C11.1193 17 10 15.8807 10 14.5V14"
                      stroke="black" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                  <span class="text-lg font-medium ml-4">Sign Out</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- user dropdown end -->
        </div>
        <div class="hamburger-close-icon lg:hidden ml-2 z-[101]">
          <div @click="toggleMenu" class="w-6 h-6 flex items-center justify-center">
            <svg v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      </div>
      <div v-else class="flex ml-[70px] justify-items-center items-center">
        <ul class="flex gap-x-5">
          <li>
            <a @click="navigate_to_login" href="#" class="text-base text-blue">Login</a>
          </li>
          <li>
            <a @click="navigate_to_signup" href="#" class="text-base text-blue">Sign up</a>
          </li>
        </ul>
        <div class="hamburger-close-icon lg:hidden ml-2">
          <div @click="toggleMenu" class="w-6 h-6 flex items-center justify-center">
            <svg v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" @click="toggleMenu" class="fixed inset-0 bg-black bg-opacity-50 z-[99]"></div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'HomeHeader',
  props: {
    full_name_header: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isTokenEmpty: true,
      isMenuOpen: false,
      routeToHeaderMap: {
        'satisfy-curiosity': 'Satisfy_My_Curiosity',
        'search-satisfy': 'Satisfy_My_Curiosity',
        'showoff-knowledge': 'Show_Off_My_Knowledge',
        'explore': 'Explore',
        'home': 'Home',
        'home-logout': 'Home',
        // 'home-search': 'Explore',
      }
    };
  },
  computed: {
    ...mapState('header_store', ['header_option']),
    profile_data() {
      const token = localStorage.getItem('token');
      if (token === undefined || token === null || token === '') {
        return null;
      } else {
        return JSON.parse(localStorage.getItem('profile_data'));
      }
    },
  },
  methods: {
    ...mapActions('header_store', ['logout_api']),

    navigateToExplore() {
      this.setHeaderOption('Explore');
      this.$router.push({
        name: 'explore'
      });
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },

    signout_user() {
      this.logout_api()
        .then(() => {
          this.$toast.open({
            message: 'Successfully Logout',
            type: 'success',
            duration: 1000 * 5,
            dismissible: true,
          });
          localStorage.clear();
          this.$router.push({ name: 'home-logout' });
        })
        .catch((error) => {
          this.$toast.open({
            message: error,
            type: 'error',
            duration: 1000 * 5,
            dismissible: true,
          });
        });
    },
    navigate_to_profile_settings() {
      setTimeout(() => {
        document.querySelector('.user-dropdown').classList.add('hidden');
      }, 100);
      this.$router.push({ name: 'profile-setting' });
    },
    navigate_to_stats() {
      setTimeout(() => {
        document.querySelector('.user-dropdown').classList.add('hidden');
      }, 100);
      this.$router.push({ name: 'stats' });
    },
    navigate_to_xp_page() {
      setTimeout(() => {
        document.querySelector('.user-dropdown').classList.add('hidden');
      }, 100);
      this.$router.push({ name: 'xp_page' });
    },
    navigate_to_journal() {
      setTimeout(() => {
        document.querySelector('.user-dropdown').classList.add('hidden');
      }, 100);
      this.$router.push({ name: 'journal' });
    },
    navigate_to_login() {
      this.$router.push({ name: 'login-account' });
    },
    navigate_to_signup() {
      this.$router.push({ name: 'create-account' });
    },
    openUserDropdown() {
      document.querySelector('.user-dropdown').classList.toggle('hidden');
    },
    setHeaderOption(option) {
      this.$store.commit('header_store/setHeaderOption', option);
      if (option === 'Home') {
        if (this.isTokenEmpty) {
          this.$router.push({ name: 'home-logout' });
        } else {
          this.$router.push({ name: 'home' });
        }
      } else if (option === 'Satisfy_My_Curiosity') {
        this.$router.push({ name: 'satisfy-curiosity' });
      } else if (option === 'Show_Off_My_Knowledge') {
        this.$router.push({ name: 'showoff-knowledge' });
      } else if (option === 'Solve_a_Problem') {
        console.log('navigate to Solve_a_Problem');
      }
    },
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isTokenEmpty = true;
    } else {
      this.isTokenEmpty = false;
    }

    const headerOption = this.routeToHeaderMap[this.$route.name];
    if (headerOption) {
      this.$store.commit('header_store/setHeaderOption', headerOption);
    }
  },
  watch: {
    '$route'(to) {
      const headerOption = this.routeToHeaderMap[to.name];
      if (headerOption) {
        this.$store.commit('header_store/setHeaderOption', headerOption);
      }
    }
  },
};
</script>

<style>
@media (max-width: 1023px) {
  .nav-links {
    transition: left 0.3s ease-in-out;
  }
}
</style>
