<template>
  <div class="category-section">
    <div class="category-item p-2">
      <!-- Main Category -->
      <div @click="() => toggleExpand(category.id)" class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center">
          <input v-if="!category.isHeader" type="checkbox" :checked="selectedFilters[category.id]"
            @change="e => handleCheckboxChange(e, category)" class="mr-2 large-checkbox" @click.stop />
          <span class="category-name font-inter" :class="{ 'font-semibold': category.isHeader }">
            {{ category.name }}
          </span>
        </div>
        <span v-if="category.children && category.children.length" class="expand-icon">
          <template v-if="isExpanded(category.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
            </svg>
          </template>
          <template v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
            </svg>
          </template>
        </span>
      </div>

      <!-- First Level Children -->
      <div v-if="category.children && isExpanded(category.id)" class="first-level-children ml-4">
        <div v-for="child in visibleChildren" :key="child.id" class="mt-1">
          <div @click="() => toggleExpand(child.id)" class="flex items-center justify-between cursor-pointer">
            <div class="flex items-center">
              <input type="checkbox" :checked="selectedFilters[child.id]" @change="e => handleCheckboxChange(e, child)"
                class="mr-2 large-checkbox" @click.stop />
              <span class="category-name font-inter">{{ child.name }}</span>
            </div>
            <span v-if="child.children && child.children.length" class="expand-icon">
              <template v-if="isExpanded(child.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                </svg>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                </svg>
              </template>
            </span>
          </div>

          <!-- Second Level -->
          <div v-if="child.children && isExpanded(child.id)" class="nested-children ml-4">
            <div v-for="secondLevel in child.children" :key="secondLevel.id" class="mt-1">
              <div @click="() => toggleExpand(secondLevel.id)" class="flex items-center justify-between cursor-pointer">
                <div class="flex items-center">
                  <input type="checkbox" :checked="selectedFilters[secondLevel.id]"
                    @change="e => handleCheckboxChange(e, secondLevel)" class="mr-2 large-checkbox" @click.stop />
                  <span class="category-name font-inter">{{ secondLevel.name }}</span>
                </div>
                <span v-if="secondLevel.children && secondLevel.children.length" class="expand-icon">
                  <template v-if="isExpanded(secondLevel.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                    </svg>
                  </template>
                  <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </template>
                </span>
              </div>

              <!-- Third Level -->
              <div v-if="secondLevel.children && isExpanded(secondLevel.id)" class="nested-children ml-4">
                <div v-for="thirdLevel in secondLevel.children" :key="thirdLevel.id" class="mt-1">
                  <div @click="() => toggleExpand(thirdLevel.id)"
                    class="flex items-center justify-between cursor-pointer">
                    <div class="flex items-center">
                      <input type="checkbox" :checked="selectedFilters[thirdLevel.id]"
                        @change="e => handleCheckboxChange(e, thirdLevel)" class="mr-2 large-checkbox" @click.stop />
                      <span class="category-name font-inter">{{ thirdLevel.name }}</span>
                    </div>
                    <span v-if="thirdLevel.children && thirdLevel.children.length" class="expand-icon">
                      <template v-if="isExpanded(thirdLevel.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                        </svg>
                      </template>
                      <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-chevron-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </template>
                    </span>
                  </div>

                  <!-- Fourth Level -->
                  <div v-if="thirdLevel.children && isExpanded(thirdLevel.id)" class="nested-children ml-4">
                    <div v-for="fourthLevel in thirdLevel.children" :key="fourthLevel.id" class="mt-1">
                      <div @click="() => toggleExpand(fourthLevel.id)"
                        class="flex items-center justify-between cursor-pointer">
                        <div class="flex items-center">
                          <input type="checkbox" :checked="selectedFilters[fourthLevel.id]"
                            @change="e => handleCheckboxChange(e, fourthLevel)" class="mr-2 large-checkbox"
                            @click.stop />
                          <span class="category-name font-inter">{{ fourthLevel.name }}</span>
                        </div>
                        <span v-if="fourthLevel.children && fourthLevel.children.length" class="expand-icon">
                          <template v-if="isExpanded(fourthLevel.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                            </svg>
                          </template>
                          <template v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-chevron-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </template>
                        </span>
                      </div>

                      <!-- Fifth Level -->
                      <div v-if="fourthLevel.children && isExpanded(fourthLevel.id)" class="nested-children ml-4">
                        <div v-for="fifthLevel in fourthLevel.children" :key="fifthLevel.id" class="mt-1">
                          <div @click="() => toggleExpand(fifthLevel.id)"
                            class="flex items-center justify-between cursor-pointer">
                            <div class="flex items-center">
                              <input type="checkbox" :checked="selectedFilters[fifthLevel.id]"
                                @change="e => handleCheckboxChange(e, fifthLevel)" class="mr-2 large-checkbox"
                                @click.stop />
                              <span class="category-name font-inter">{{ fifthLevel.name }}</span>
                            </div>
                            <span v-if="fifthLevel.children && fifthLevel.children.length" class="expand-icon">
                              <template v-if="isExpanded(fifthLevel.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd"
                                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                                </svg>
                              </template>
                              <template v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  class="bi bi-chevron-right" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg>
                              </template>
                            </span>
                          </div>

                          <!-- Sixth Level -->
                          <div v-if="fifthLevel.children && isExpanded(fifthLevel.id)" class="nested-children ml-4">
                            <div v-for="sixthLevel in fifthLevel.children" :key="sixthLevel.id" class="mt-1">
                              <div @click="() => toggleExpand(sixthLevel.id)"
                                class="flex items-center justify-between cursor-pointer">
                                <div class="flex items-center">
                                  <input type="checkbox" :checked="selectedFilters[sixthLevel.id]"
                                    @change="e => handleCheckboxChange(e, sixthLevel)" class="mr-2 large-checkbox"
                                    @click.stop />
                                  <span class="category-name font-inter">{{ sixthLevel.name }}</span>
                                </div>
                                <span v-if="sixthLevel.children && sixthLevel.children.length" class="expand-icon">
                                  <template v-if="isExpanded(sixthLevel.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                      class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd"
                                        d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                                    </svg>
                                  </template>
                                  <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                      class="bi bi-chevron-right" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                  </template>
                                </span>
                              </div>

                              <!-- Seventh Level -->
                              <div v-if="sixthLevel.children && isExpanded(sixthLevel.id)" class="nested-children ml-4">
                                <div v-for="seventhLevel in sixthLevel.children" :key="seventhLevel.id" class="mt-1">
                                  <div @click="() => toggleExpand(seventhLevel.id)"
                                    class="flex items-center justify-between cursor-pointer">
                                    <div class="flex items-center">
                                      <input type="checkbox" :checked="selectedFilters[seventhLevel.id]"
                                        @change="e => handleCheckboxChange(e, seventhLevel)" class="mr-2 large-checkbox"
                                        @click.stop />
                                      <span class="category-name font-inter">{{ seventhLevel.name }}</span>
                                    </div>
                                    <span v-if="seventhLevel.children && seventhLevel.children.length"
                                      class="expand-icon">
                                      <template v-if="isExpanded(seventhLevel.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                          <path fill-rule="evenodd"
                                            d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                                        </svg>
                                      </template>
                                      <template v-else>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                          <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                      </template>
                                    </span>
                                  </div>

                                  <!-- Eighth Level -->
                                  <div v-if="seventhLevel.children && isExpanded(seventhLevel.id)"
                                    class="nested-children ml-4">
                                    <div v-for="eighthLevel in seventhLevel.children" :key="eighthLevel.id"
                                      class="mt-1">
                                      <div @click="() => toggleExpand(eighthLevel.id)"
                                        class="flex items-center justify-between cursor-pointer">
                                        <div class="flex items-center">
                                          <input type="checkbox" :checked="selectedFilters[eighthLevel.id]"
                                            @change="e => handleCheckboxChange(e, eighthLevel)"
                                            class="mr-2 large-checkbox" @click.stop />
                                          <span class="category-name font-inter">{{ eighthLevel.name }}</span>
                                        </div>
                                        <span v-if="eighthLevel.children && eighthLevel.children.length"
                                          class="expand-icon">
                                          <template v-if="isExpanded(eighthLevel.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              fill="currentColor" class="bi bi-chevron-compact-down"
                                              viewBox="0 0 16 16">
                                              <path fill-rule="evenodd"
                                                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                                            </svg>
                                          </template>
                                          <template v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                              <path fill-rule="evenodd"
                                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                          </template>
                                        </span>
                                      </div>

                                      <!-- Ninth Level -->
                                      <div v-if="eighthLevel.children && isExpanded(eighthLevel.id)"
                                        class="nested-children ml-4">
                                        <div v-for="ninthLevel in eighthLevel.children" :key="ninthLevel.id"
                                          class="mt-1">
                                          <div @click="() => toggleExpand(ninthLevel.id)"
                                            class="flex items-center justify-between cursor-pointer">
                                            <div class="flex items-center">
                                              <input type="checkbox" :checked="selectedFilters[ninthLevel.id]"
                                                @change="e => handleCheckboxChange(e, ninthLevel)"
                                                class="mr-2 large-checkbox" @click.stop />
                                              <span class="category-name font-inter">{{ ninthLevel.name }}</span>
                                            </div>
                                            <span v-if="ninthLevel.children && ninthLevel.children.length"
                                              class="expand-icon">
                                              <template v-if="isExpanded(ninthLevel.id)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-chevron-compact-down"
                                                  viewBox="0 0 16 16">
                                                  <path fill-rule="evenodd"
                                                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                                                </svg>
                                              </template>
                                              <template v-else>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                  <path fill-rule="evenodd"
                                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                                </svg>
                                              </template>
                                            </span>
                                          </div>

                                          <!-- Tenth Level -->
                                          <div v-if="ninthLevel.children && isExpanded(ninthLevel.id)"
                                            class="nested-children ml-4">
                                            <div v-for="tenthLevel in ninthLevel.children" :key="tenthLevel.id"
                                              class="mt-1">
                                              <div class="flex items-center">
                                                <input type="checkbox" :checked="selectedFilters[tenthLevel.id]"
                                                  @change="e => handleCheckboxChange(e, tenthLevel)"
                                                  class="mr-2 large-checkbox" />
                                                <span class="category-name font-inter">{{ tenthLevel.name }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button v-if="category.children.length > itemsPerPage && !showAllItems" @click.stop="showAllItems = true"
          class="text-blue hover:underline mt-4 text-sm font-medium">
          See More ({{ category.children.length - itemsPerPage }} more)
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: 'CategoryItem',

  props: {
    category: {
      type: Object,
      required: true,
      validator: (prop) => {
        if (prop.isHeader !== undefined) {
          return typeof prop.isHeader === 'boolean';
        }
        return true;
      }
    },
    selectedFilters: {
      type: Object,
      required: true
    },
    expandedCategories: {
      type: Object,
      required: true
    }
  },

  emits: ['toggle', 'filter-change'],

  setup(props, { emit }) {
    const showAllItems = ref(false);
    const itemsPerPage = 3;

    const hasChildren = computed(() => {
      return props.category.children && props.category.children.length > 0;
    });

    const visibleChildren = computed(() => {
      if (!props.category.children) return [];
      return showAllItems.value
        ? props.category.children
        : props.category.children.slice(0, itemsPerPage);
    });

    const isExpanded = (categoryId) => {
      return props.expandedCategories[categoryId] || false;
    };

    const toggleExpand = (categoryId) => {
      emit('toggle', categoryId);
    };

    const handleCheckboxChange = (event, category) => {
      event.stopPropagation();
      emit('filter-change', category.id, category.name, event.target.checked);
    };

    return {
      showAllItems,
      itemsPerPage,
      hasChildren,
      visibleChildren,
      isExpanded,
      toggleExpand,
      handleCheckboxChange
    };
  }
};
</script>

<style scoped>
.expand-icon {
  font-size: 12px;
  color: #666;
  padding: 4px;
}

.large-checkbox {
  transform: scale(1.5);
  margin-right: 8px;
}

.nested-children {
  transition: all 0.3s ease;
}

.category-name.font-semibold {
  font-size: 1.1em;
  color: #333;
}
</style>